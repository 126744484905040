<template>
  <div >
     <dv-full-screen-container>
      <div class="jl-box" @click="handleFullScreen">
<!--          <div class="head-top">
              <ul>
                <li><div class="time-day">{{FormatTime(nowTime)}}</div></li>
                <li><div class="but" @click="getRouter(1)">指挥</div></li>
                <li><div class="but" @click="getRouter(2)">直播</div></li>
              </ul>
              <h1>吉林省公安厅警用航空器指挥管理平台</h1>
              <ul>
                <li> <div class="but" @click="getRouter(3)">航线</div></li>
                <li> <div class="but" @click="getRouter(4)">记录</div></li>
                <li> <div class="but" @click="getRouter(5)">成果</div></li>
              </ul>
          </div>-->
         <div class="head-top">
          <div class="main_fl-img fl">
            <img src="./img/gh.png" />
            <strong>吉林警航</strong>
          </div>
          <h1>吉林省公安厅警用航空器指挥管理平台</h1>
          <ul>
            <!--      <li> <div class="but" @click="getRouter(4)">记录</div></li>-->
            <li> <div class="but" @click="getRouter(5)">成果</div></li>
            <li> <div class="but" @click="getRouter(3)">航线</div></li>
            <li><div class="but" @click="getRouter(2)">直播</div></li>
            <li><div class="but" @click="getRouter(1)">指挥</div></li>
          </ul>
        </div>
          <div class="jl-main-box">
            <div class="main_fl fl">
              <tree />
<!--              <div>
                <TeamTreeCascader ref="teamTree" v-if="user_info.permission == 1" />
              </div>-->
              <br/>
              <titleTop  name="飞行距离"/>
              <barGraph  :seriesDate="seriesDate"/>
              <div class="main_fl-img">
<!--                <img src="./img/gh.png" />
                <strong>吉林警航</strong>-->
                <div class="time-day">{{FormatTime(nowTime)}}</div>
              </div>
            </div>
            <div class="main_content fl">
            <!--    吉林图           -->
                 <div>
                    <JiLinTu/>
                 </div>
              <!--     折线图         -->
                  <div style="margin-top: 20px">
                     <category :seriesDate="seriesDate"  :smooth="true"/>
                  </div>
            </div>
            <div class="main_fr fr">
                  <div>
                    <titleTop  name="飞行信息"/>
                    <ul class="frItem">
                      <li v-for="(it,index) in wrjTotal" :key="index">
                        <strong>{{ it.name }}</strong>
                        <p>{{it.num}}</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <titleTop  name="飞行次数"/>
                    <pieChart  :pieDataList="pieData" />
                  </div>
                  <div>
                    <titleTop  name="每日飞行里程"/>
                    <category height="210px" :seriesDate="seriesDate"  :smooth="false"/>
                  </div>
                  <div>
                    <titleTop  name="每日飞行里程"/>
                    <ul class="frItem2">
                      <li v-for="(it,index) in distanceres" :key="index">
                        <p class="floatClose">
                          <span class="fl">{{ index+1 }}</span>
                          <span class="fl">{{ it.DRONEMODEL }}</span>
                          <span class="fr">{{ it.distance }}min</span>
                        </p>
                        <div class="color-box">
                          <div :style="'width:'+ (it.distance/distanceresTotal)*100+'%'"></div>
                        </div>
                      </li>
                    </ul>
                  </div>

            </div>
          </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
//引入自适应缩放文件
import Api from '@/utils/api.js';
import tree from "./tree";
import titleTop from "./titleTop";
import barGraph from "./barGraph";
import category from "./category";
import JiLinTu from "./JiLinTu";
import pieChart from "./pieChart";
import TeamTreeCascader from '@/components/team/teamTreeCascader.vue'
import _ from "lodash";
export default {
  name: "JilinProvince",
  components:{
    tree,
    titleTop,
    barGraph,
    category,
    JiLinTu,
    pieChart,
    TeamTreeCascader
  },
  data(){
    return{
      timer: undefined,
      nowTime: new Date(),
      wrjTotal:[
        {name:'飞行次数',num:""},
        {name:'飞行时间',num:""},
        {name:'飞行距离',num:""},
      ],
      filterOptions: {
        startdate:'',
        enddate:'',
        team: -1,
      },
      pieData:[], //饼状图
      distanceres:[],//每日飞行里程
      distanceresTotal:0,//总长度
      seriesDate:[], //折线图
      user_info:{}
    }
  },
  computed: {
    // 获取数据的参数
    fetchParams: {
      get() {
        return {
          startdate: this.filterOptions.startdate,
          enddate:this.filterOptions.enddate,
          team_id: this.user_info.permission == 0 ? this.user_info.team_id : this.filterOptions.team,
        };
      },
    },
  },
  methods:{
    // 全屏事件
    handleFullScreen() {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      } else if (de.msRequestFullscreen) {
        de.msRequestFullscreen();
      }
    },
    getRouter(e){
      switch (parseInt(e)) {
        case 1:
          this.$router.push('/live/watch-lists')
          break;
        case 2:
          this.$router.push('/live/live-lists')
          break;
        case 3:
          this.$router.push('/index/project')
          break;
        case 4:
          this.$router.push('/statistics/records')
          break;
        case 5:
          this.$router.push('/index/resource/modal')
          break;
        default:
          break;

      }
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    // 获取数据
    fetchData() {
      this.$post(Api.getRrackAllCount(), _.merge({}, this.fetchParams)).then((res) => {
          //  console.log('1111111统计111111111111',res);
            if (res.data) {
              this.pageData = res.data;
              this.distanceres=[]
              this.distanceresTotal=0
              res.data.distanceres.forEach((e,index)=>{
                if(index<5){
                  this.distanceres=[...this.distanceres,e]
                  this.distanceresTotal +=parseInt(e.distance)
                }
              })
              this.seriesDate=res.data.dis_every_by_month
            //  console.log('res.data.dis_every_by_month',res.data.dis_every_by_month)
              /*飞行信息*/
              this.wrjTotal[0].num=this.pageData.countres.alltime
              this.wrjTotal[1].num=this.pageData.countres.count
              this.wrjTotal[2].num=this.pageData.countres.distance
              this.pieData=this.pageData.flycount
            }
          })

    },
    // 显示当前时间
    FormatTime() {
      //返回显示的日期时间格式
      var date = new Date();
      var year = this.formatTime(date.getFullYear());
      var month = this.formatTime(date.getMonth() + 1);
      var day = this.formatTime(date.getDate());
      var hour = this.formatTime(date.getHours());
      var minute = this.formatTime(date.getMinutes());
      var second = this.formatTime(date.getSeconds());
   //   return `${year}-${month}-${day} ${hour}:${minute}:${second} `;
      this.filterOptions.startdate=`${year}-01-01`
      this.filterOptions.enddate=`${year}-12-30`

      return `${year}-${month}-${day} ${hour}:${minute}`;

    },
    formatTime(n) {
      //判断是否需要加0
      if (n < 10) {
        return "0" + n;
      } else {
        return n;
      }
    },
  },
  mounted() {
    if(this.fetchParams.team_id===-1){
      this.fetchData();
    }
    this.user_info = JSON.parse(
        window.sessionStorage.getItem('user_info') || '{}'
    );
    if (sessionStorage.getItem('team_id') === null) {
      this.filterOptions.team = this.user_info.team_id;
      this.fetchData();
    } else {
      this.filterOptions.team = Number(sessionStorage.getItem('team_id'));
      console.log(' this.filterOptions.team', this.filterOptions.team)
      this.fetchData();
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.filterOptions.team = value;
      this.fetchData();
     /* console.log('222222222222222222222',this.user_info.permission)*/
    });

  },
  created() {
    // 显示时间，在渲染页面之前一直调用该函数，对this.time进行赋值
    this.timer = setInterval(() => {
      this.nowTime= new Date().toLocaleString();
    })
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

}
</script>

<style scoped lang="scss">
body{
  margin: 0!important;
  padding: 0!important;
}
.fl{
  float: left;
}
.fr{
  float: right;

}
.floatClose{
  overflow: hidden;
}
.jl-box{
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(225deg, rgba(10, 35, 91, 1) 0%, rgba(4, 11, 30, 1) 100%);
  .head-top{
    width: 1920px;
    height:93px;
    background: url("./img/jltop.png");
    background-size:100% 100% ;
    display: inline-block;
    overflow: hidden;
    .main_fl-img{
      width: 30%;
      padding: 5px 10px;
      img{
        width: 110px;
        height: 45px;
        display: inline-block;
        vertical-align: middle;
      }
      strong{
        vertical-align: middle;
        font-size: 32px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38px;
      }
    }
    ul{
      width: 30%;
      height: 60px;
      display: inline-block;
      overflow: hidden;
      float: left;
      li{
        max-width: 33%;
        color: white;
        text-align: center;
        float: left;
      /*  .time-day{
          width: 140px;
          height: 36px;
          margin: 10px;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          background: url("./img/time.png") no-repeat;
          background-size: 100% 100%;
        }*/
        .but{
          width: 115px;
          height: 34px;
          line-height: 34px;
          background: linear-gradient(180deg, #327BCC 0%, #042956 100%);
          opacity: 1;
          margin: 10px;
          cursor: pointer;
        }
      }
    }
    ul:last-child{
       li{
         float: right;
       }
    }
    .time-day{
      font-size: 16px;
      font-family: DIN Alternate-Bold, DIN Alternate;
      font-weight: bold;
    }
    h1{
      width:40%;
      display: inline-block;
      text-align: center;
      height: 60px;
      line-height: 60px;
      font-size: 32px;
      color: white;
      margin: 0 auto;
      float: left;
    }
  }
  .jl-main-box{
     width: 100%;
     overflow: hidden;

    .main_fl{
      width: 460px;
      margin: 10px 50px 0 40px;
      float: left;
      .main_fl-img{
        margin-top:25px;
        .time-day{
          width: 140px;
          height: 36px;
          margin: 10px;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          color: white;
          background: url("./img/time.png") no-repeat;
          background-size: 100% 100%;
          border: 1px #fff dashed;
        }

        img{
          width: 110px;
          height: 45px;
          display: inline-block;
          vertical-align: middle;
        }
        strong{
          vertical-align: middle;
          font-size: 32px;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 38px;
        }
      }
    }
    .main_content{
      float: left;
      width: 820px;

    }
    .main_fr{
      width: 460px;
      float: right;
      margin-right: 35px;
      >div{
        margin-bottom:20px ;
      }
      ul.frItem{
        overflow: hidden;
        margin-top: 10px;
        li{
          width: 33%;
          float: left;
          height:60px;
          padding-left: 10px;
          strong{
            font-size: 14px;
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            color: white;
          }
          p{
            margin-top:4px;
            width: 120px;
            height: 34px;
            font-size: 22px;
            font-weight: normal;
            color: #44C5E9;
            line-height: 34px;
            letter-spacing: 1px;
            text-shadow: 0 0 8px rgba(255, 119, 52, 0.5);
            background: rgba(108, 128, 151, 0.1);
          }
        }
      }
      ul.frItem2{
        width: 100%;
        li{
          margin-top: 10px;
          height: 32px;
          p{
            font-size: 14px;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #FFFFFF;
            span.fl{
              margin-left: 20px;
            }
            span.fr{
              margin-right: 20px;
            }
          }
          .color-box{
            width: 418px;
            margin: 0 auto;
            height: 4px;
            background: rgba(217, 231, 255, 0.1);
            opacity: 1;
            margin-top: 5px;
            div{
              height: 4px;
              background: linear-gradient(63deg, #1B7EF2 0%, #28F1FA 100%);
              opacity: 1;

            }
          }
        }

      }
    }

  }

}


</style>

