<template>
  <div class="title-box">
    <h3>
      <span class="fl"></span>
      <b class="fl">{{name}}</b>
      <em class="fr"/>
    </h3>
  </div>
</template>

<script>
export default {
name: "titleTop",
  props:{
    name:{
      type:String
    }
  }
}
</script>

<style scoped lang="scss">
.title-box{
  width: 100%;
  height: 40px;
  border-radius: 0;
  background: linear-gradient(90deg, #0055FF 0%, rgba(106, 112, 124, 0) 70%, rgba(106, 112, 124, 0) 100%);
  border: 1px solid;
  border-image: linear-gradient(90deg, #0055FF, rgba(217, 231, 255, 0)) 1 1;
  font-weight: bold;
  color: #FFFFFF;
  overflow: hidden;
  h3{
    height: 40px;
    line-height: 40px;
  }
  b{
    line-height: 40px;
    margin-left:15px;
  }
  span{
    width: 7px;
    height: 38px;
    background: #2276FC;
    display: inline-block;
    opacity: 1;
  }
  em{
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius:2px;
    background: white;
    margin-top: 18px;
    margin-right: 20px;
  }
}
.fl{
  float: left;
}
.fr{
  float: right;
}
</style>
