<template>
  <div>
    <h3>
      <strong>单位：米</strong>
    </h3>
    <div :id="id"  :style="'width: '+width+';height:'+height+';'"></div>
  </div>
</template>

<script>
let mapId = 0
import * as echarts from 'echarts';
export default {
  name: "category" , //折线图
  props:{
    height:{
      type:String,
      default:'300px'
    },
    width:{
      type:String,
      default:'100%'
    },
    seriesDate:{
      type:Array,
    },
    smooth:{
      type:Boolean,
      default:false
    }
  },
  watch: {
    seriesDate: {
      handler(newName, oldName) {
        this.initDate(newName);
      },
      deep: true,
      immediate: true
    }
  },
  data(){
    return{
      color: [
        "#FF5D1D",
        "#FFA91F",
        "#FFF803",
        "#9DF90D",
        "#11EA00",
        "#00FFF8",
        "#038CEA",
        "#2948FF",
        "#B283FC",
        "#A000EA",
        "#F746EA",
        "#AF1E59"
      ],
      nameList:[],
      valueList:[],
      series:[]
    }
  },
  computed: {
    // 每个地图的id 有可能一个页面多个map
    id: {
      get() {
        return 'category' + mapId++
      },
    },
  },
  mounted() {
    this.initDate(); //数据初始化
    this.initEcharts()
  },
  methods:{
    initDate() {
      this.valueList=[];
      this.nameList=[]
      if(this.seriesDate.length>0){
        this.seriesDate.forEach(e=>{
          this.nameList=[...this.nameList,e.date]
          this.valueList=[...this.valueList,e.distance]
        })

        if(this.smooth){
          this.series=[
            {
              name: '飞行信息',
              data: this.valueList,
              type: 'line',
              smooth: this.smooth,  //平滑折线图
              itemStyle: {
                color:'#159AFF'
              },
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(27, 126, 242, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(41, 241, 250, 0)'
                  }
                ])
              },
            },
          ]
        }else {
          this.series=[
            {
              name: '飞行信息',
              data: this.valueList,
              type: 'line',
              smooth: this.smooth,  //平滑折线图
              itemStyle: {
                color:'#159AFF'
              },
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(27, 126, 242, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(41, 241, 250, 0)'
                  }
                ])
              },
            },
          ]
        }
        this.initEcharts()
      }
    },
    initEcharts(){
      var chartDom = document.getElementById(this.id)
      var myChart = echarts.init(chartDom);
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '1%',
          right: '4%',
          bottom: '1%',
          top:'4%',
          containLabel: true

        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月'],
          data:this.nameList,
          axisLine: {
            lineStyle: {
              color: 'rgba(11, 197, 197,0.8)',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: 'rgba(11, 197, 197,0.8)',
            },
          },
        },
        series:this.series
       /*  series: [
            {
              name: '飞行信息',
              data: this.valueList,
              type: 'line',
              smooth: this.smooth,  //平滑折线图
              itemStyle: {
                color:'#159AFF'
              },
              areaStyle: {
                opacity: 0.8,
                color: '#159AFF'
              },
            },
          ]*/
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="scss">
/*#categoryId{
  width: 100%;
  height:300px;
}*/
h3{
  width: 100%;
  height: 18px;
  margin: 10px 0;
  overflow: hidden;
  strong{
    height: 18px;
    color: #6C8097;
    line-height: 18px;
    float: left;
    font-size: 20px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.64);

  }
  span{
    float: right;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: linear-gradient(180deg, rgba(21, 154, 255, 0) 0%, #159AFF 100%);
    box-shadow: inset 0px 2px 0px 1px #159AFF;
    opacity: 1;
    margin-top: 5px;
    margin-right: 10px;
  }
  label{
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #D0DEEE;
    line-height: 12px;
    float: right;
    margin-top: 3px;
    display: inline-block;
  }


}

</style>
