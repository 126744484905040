<template>
  <div>
      <h3>
        <strong>万人</strong>
        <label>人员数量</label>
        <span></span>
      </h3>
     <div id="echartsId"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
name: "barGraph" , //柱状图
  props:{
    seriesDate:{
      type:Array,
    },
  },
  data(){
    return{
      nameList:[],
      valueList:[],
      series:[]
    }
  },
  watch: {
    seriesDate: {
      handler(newName, oldName) {
        this.initDate(newName);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initDate(); //数据初始化
    this.initEcharts()
  },
  methods:{
    initDate() {
      this.valueList=[];
      this.nameList=[]
      if(this.seriesDate.length>0){
        this.seriesDate.forEach(e=>{
          this.nameList=[...this.nameList,e.date]
          this.valueList=[...this.valueList,e.distance]
        })
        this.initEcharts()
      }
    },
    initEcharts(){
      var chartDom = document.getElementById('echartsId')
      var myChart = echarts.init(chartDom);
      var  option = {
        grid: {
          left: '1%',
          right: '4%',
          bottom: '1%',
          top:'4%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.nameList,
          axisLine: {
            lineStyle: {
              color: '#ffffff',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#ffffff',
            },
          },
        },
        series: [
          {
            name: 'Forest',
            data:this.valueList,
            type: 'bar',
            showBackground: true,
            barWidth: '40%',
            backgroundStyle: {
              color: 'rgb(245,253,1,0.1)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: 'rgba(0, 58, 255, 0)' },
                { offset: 0, color: '#159AFF' }
              ])
            },
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="scss">
#echartsId{
  width: 100%;
  height:200px;
}
h3{
  width: 100%;
  height: 18px;
  margin: 10px 0;
  overflow: hidden;
  strong{
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #6C8097;
    line-height: 18px;
    float: left;

  }
  span{
    float: right;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: linear-gradient(180deg, rgba(21, 154, 255, 0) 0%, #159AFF 100%);
    box-shadow: inset 0px 2px 0px 1px #159AFF;
    opacity: 1;
    margin-top: 5px;
    margin-right: 10px;
  }
  label{
    height: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #D0DEEE;
    line-height: 12px;
    float: right;
    margin-top: 3px;
    display: inline-block;
  }


}

</style>
