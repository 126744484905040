<template>
     <div>
       <div calss="dvChart">
           <div id="pieChart" :style="myChartStyle"></div>
       </div>
     </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "pieChart",
  props:{
    pieDataList:{
      type:Array,
    }
  },
  watch: {
    pieDataList: {
      handler(newName, oldName) {
        // console.log(newName)
       this.initDate(newName);
      },
      deep: true,
      immediate: true
    }
  },
  data(){
    return{
      color: [
        "#FF5D1D",
        "#FFA91F",
        "#FFF803",
        "#9DF90D",
        "#11EA00",
        "#00FFF8",
        "#038CEA",
        "#2948FF",
        "#B283FC",
        "#A000EA",
        "#F746EA",
        "#AF1E59"
      ],
      myChart: {},
      pieData: [
        {
          value: 463,
          name: "测试"
        },
      ],
      pieName: [],
      myChartStyle: { float: "left", width: "100%", height: "210px" } //图表样式
    }
  },
  methods:{
    initDate() {
      this.pieName=[];
      this.pieData=[]
      if(this.pieDataList.length>0){
        this.pieDataList.forEach((e,index)=>{
          if(index<5){
            this.pieName=[...this.pieName,e.DRONEMODEL]
            this.pieData=[...this.pieData,{name:e.DRONEMODEL,value:e.count }]

          }
        })
        this.initEcharts();
      }

    },
    initEcharts() {
      // 饼图
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          // 图例
          data: this.pieName,
          right: "10%",
          top: "10%",
          orient: "vertical",
          textStyle:{
            color: '#fff'
          }
        },
        series: [
          {
            type: "pie",
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            color: [
              "#FF5D1D",
              "#FFA91F",
              "#FFF803",
              "#00FFF8",
              "#038CEA",
              "#9DF90D",
              "#2948FF",
              "#B283FC",
              "#11EA00",
              "#A000EA",
              "#F746EA",
              "#AF1E59"
            ],
            radius: ['60%', '80%'],//饼图半径
            data: this.pieData,
            left: "-50%",
            top: "2%",
          }
        ]
      };
      this.myChart = echarts.init(document.getElementById("pieChart"));
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }

  },
  mounted() {
    this.initDate(); //数据初始化
    this.initEcharts();
  }
}
</script>

<style scoped>

</style>
